import React, {useEffect, useState} from 'react'
import Layout from '../components/layout'
import '../css/pages/auth.scss'
import {useForm} from 'react-hook-form'
import Navigate from '../components/navigate'
import {isBrowser} from '../utils/articleUtil'
import * as queryString from 'query-string'
import {URL} from '../constants/urls'

function SudokuWaitlist (props) {
  const [pending, setPending] = useState(false);
  const { handleSubmit, register, formState: { errors }, getValues, setError, setValue } = useForm();

  useEffect(() => {
    if (isBrowser()) {
      const params = queryString.parse(window.location.search);
      if (!params) {
        return;
      }
      if (params.name) {
        setValue('name', params.name);
      }
      if (params.email) {
        setValue('email', params.email);
      }
    }
  }, []);

  const onSubmit = ({ email, name }) => {
    // Show pending indicator
    setPending(true);

    fetch(`${URL.SERVER}/subscribe/sudoku-waitlist?name=${encodeURIComponent(name)}&email=${encodeURIComponent(email)}`)
      .then(resp => resp.json())
      .then(res => {
        setError('submitSuccess', {
          message: 'Thanks, we will inform you.'
        });
      }).finally(() => {
      setPending(false);
    });
  };

  const inputClass = (inputName) => {
    return `w-full text-base rounded-default py-3 px-4 text-gray-560 border-default border-gray-360 mt-3${errors?.[inputName] ? ' border-red' : ''}`;
  }

  const errorClass = `m-0 pt-1 text-red text-sm text-left`;

  return (
    <Layout hideHeader={true} hideFooter={true}>
      <main className="wrapper main-data-container" style={{padding: 0}} role="main" data-datocms-noindex>
        <div className={"grid grid-cols-12 gap-2 bg-gray-100"} style={{minHeight: "100vh"}}>
          <div className={"col-span-12 lg:col-span-6 h-[200px] lg:h-auto m-4 rounded-2xl lg:m-0 lg:rounded-none bg-login-sudoku-mobile lg:bg-login-sudoku-dt bg-cover bg-center h-100vh lg:h-unset"}>&nbsp;</div>
          <div className={"col-span-12 lg:col-span-6 text-center lg:pt-10 lg:order-first"}>
            <div className={"flex justify-center items-center mb-4"}>
              <p className={"font-futura-md-bt text-3xl m-0 mr-2"}>SUDOKU</p>
              <p className={"font-futura-md-bt text-2xl m-0 mr-2"}>by</p>
              <img className={""} src="https://www.datocms-assets.com/38567/1685963569-ag-logo-final.png" width={150}/>
            </div>
            <p className={"font-futura-md-bt text-2xl lg:max-w-[500px] m-0 pb-2 mx-auto"} style={{lineHeight: 1.1}}>Provide your contact details to lock in your spot for Sudoku.</p>
            <p className={"font-futura-lt-bt text-xl lg:max-w-[500px] mb-5 mx-auto"} style={{lineHeight: 1.1}}>We will inform you once our Sudoku game (beta version) is ready for you to play and compete for exclusive prizes.</p>
            <div className={""}>
              <form onSubmit={handleSubmit(onSubmit)} className={"max-w-[360px] m-auto"}>
                <div className={"grid grid-cols-12 gap-2 font-futura-bk-bt"}>
                  {errors?.submit && <div className={"col-span-12"}>
                    <p className={errorClass}>{errors.submit.message}</p>
                  </div>}
                  {errors?.submitSuccess && <div className={"col-span-12"}>
                    <p className={"m-0 pt-1 text-ag text-sm text-left"}>{errors.submitSuccess.message}</p>
                  </div>}
                  <div className={"col-span-12"}>
                    <input type="name"
                           name="name"
                           placeholder="Name"
                           defaultValue={props.prefillData?.name}
                           className={inputClass('name')}
                           {...register('name', {
                             required: "Please enter your name",
                           })}
                    />
                    <p className={errorClass}>{errors.name && <span>{errors.name.message}</span>}</p>
                  </div>
                  <div className={"col-span-12"}>
                    <input type="email"
                           name="email"
                           placeholder="Email"
                           defaultValue={props.prefillData?.email}
                           className={inputClass('email')}
                           {...register('email', {
                             required: "Please enter your email",
                           })}
                    />
                    <p className={errorClass}>{errors.email && <span>{errors.email.message}</span>}</p>
                  </div>

                  <div className={"col-span-12"} style={{ display: "flex"}}>
                    <button
                      className={"mt-8 border-ag text-ag border-default rounded-default bg-white w-full font-futura-md-bt mb-5 h-[70px]"}
                      disabled={pending}>
                      {!pending && <span>Submit</span>}

                      {pending && <img
                        src="/images/loading-blue.svg"
                      />}
                    </button>
                  </div>
                </div>
                <div className={"w-full text-sm"}>
                  <div className={"mb-2"}>
                    By signing up, you are agreeing to our{" "}
                    <Navigate className={"text-ag"} href="/website-terms-of-use" passHref={true}>
                      Terms of Service
                    </Navigate>{" "}
                    and{" "}
                    <Navigate className={"text-ag"} href="/website-privacy-policy" passHref={true}>
                      Privacy Policy
                    </Navigate>
                    .
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </main>
    </Layout>
  )
}

export default SudokuWaitlist
